import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { Work } from "../components/pageLayout/Work";
import { toPlainText } from "../utils/common";
import { trimLongString } from "../utils/common";

export const query = graphql`
  query WorkTemplateQuery($id: String!) {
    work: sanityWork(id: { eq: $id }) {
      id
      title
      # shortTitle
      slug {
        current
      }
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        asset {
          id
          gatsbyImageData(layout: FULL_WIDTH)
          url
        }
      }
      _rawDescription
      description {
        _type
        _key
        children {
          _key
          _type
          marks
          text
        }
      }
      works {
        ... on SanityImageItem {
          alt
          caption
          asset {
            gatsbyImageData
          }
        }
        ... on SanityVideoItem {
          url
          caption
        }
      }
    }
  }
`;

const WorkTemplate = ({ data }) => {
  const work = data.work;
  const seoDescription = work.description
    ? trimLongString(toPlainText(work.description), 300)
    : "";
  const seoImage = work.mainImage?.asset?.url;

  console.log(work);

  return (
    <>
      <Seo title={work.title} image={seoImage} description={seoDescription} />
      <Work work={work} />
    </>
  );
};

export default WorkTemplate;
