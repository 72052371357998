import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Hero } from "../../common/Hero";
import { BlockContent } from "../../common/BlockContent";
import { ImageMediaGrid } from "../../common/ImageMediaGrid";

import * as styles from "./Work.module.scss";

const Work = ({ work }) => {
  return (
    <article>
      <Hero image={work.mainImage.asset.gatsbyImageData} alt="" />
      <h1>{work.title}</h1>
      <div className={styles.info}>
        <section className={styles.description}>
          <BlockContent blocks={work._rawDescription || []} />
        </section>
      </div>

      <section>
        <ImageMediaGrid items={work.works} defaultAlt={work.title} />
      </section>
    </article>
  );
};

export { Work };
